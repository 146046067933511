<template>
  <div class="content-box">
    <div class="content-header">
      <el-input
        v-model.trim="queryForm.deviceName"
        maxlength="50"
        class="input"
        clearable
        placeholder="请输入设备名称"
      ></el-input>
      <el-input
        v-model.trim="queryForm.storeName"
        maxlength="50"
        class="input"
        clearable
        placeholder="请输入关联门店名称"
      ></el-input>
      <el-button @click="search" type="plain" class="cotent-btn">搜索</el-button>
      <el-button type="plain" class="cotent-btn-big" size="medium"  icon="el-icon-plus" @click="handleEdit">新增激活脉诊设备</el-button>
      <el-button @click="refreshDeviceTime" type="primary">刷新到期时间</el-button>
    </div>
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%" max-height="700" :header-cell-style="headerCellStyle" border v-loading="loading" :cell-style="cellStyle">
        <el-table-column prop="deviceName" label="设备名称">
        </el-table-column>
        <el-table-column prop="storeName" label="关联门店">
        </el-table-column>
        <el-table-column prop="renewalDuration" label="自动续费时长">
        </el-table-column>
        <el-table-column prop="renewalDurationTime" label="自动续费时间">
        </el-table-column>
        <el-table-column prop="status" label="自动续费状态">
          <template #header>
            自动续费状态
            <el-popover
              placement="top-start"
              title="自动续费状态解释："
              width="300"
              trigger="hover">
              <template #reference>
                <i class="el-icon-warning-outline c-mouse"></i>
              </template>
              <div>
                <div>
                  <p>①无需续费：指设备状态暂时无需续费。</p>
                  <p>②续费失败：指用户已通过小程序续费，但是脉诊接口调用失败。</p>
                  <p>③续费登记：指用户已通过小程序续费，脉诊接口调用成功。</p>
                  <p>④已续费：指用户已续费，接口已调用，财务已结清费用。</p>
                </div>
              </div>
            </el-popover>
            
          </template>
          <template #default="{row}">
            <span :class="[`renew-status-${row.status}`]">
              {{ERenewStatusDesc[row.status]}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="激活时间"  :formatter="formatDate">
        </el-table-column>
        <el-table-column prop="expired" label="到期时间">
        </el-table-column>
        <el-table-column prop="action" label="操作">
          <template #default="{row}">
            <el-button v-if="row.deviceStatus == 2" @click="renew(row)" type="text">续费</el-button>
            <!-- <el-button v-if="ERenewStatus.REGISTER == row.status" @click="settle(row)" type="text">结算</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        style="margin-top: 20px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="itemTotal"
      ></el-pagination>


      <el-dialog title="激活" :visible.sync="dialogVisible" width="460px" :before-close="dialogClose">
        <div class="edit-box">
          <el-form label-position="left" label-width="100px" :model="formData" ref="validateForm" :rules="rules">
            <el-form-item label="设备名称:" prop="deviceName">
              <el-input v-model="formData.deviceName" class="dialogInput" placeholder="请输入设备名称" maxlength="30">
              </el-input>
            </el-form-item>

          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose">取消</el-button>
            <el-button type="primary" @click="dialogConfirm">激活</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import { ERenewStatus, ERenewStatusDesc } from "@/enum/pulseTaking.enum";

import { cloneDeep } from "lodash";

import {
  deviceList,
  activateDevice,
  queryService,
  settlement,
  renew
} from "@/api/qiaoDevice";


// 工具
import deepCopy from "@/utils/deepCopy";
import search from "@/utils/search";

const initFormData = {
  deviceName: "",
};

@Component
export default class qiaoDeviceList extends Mixins(loading, tablePagination, Resize) {
  ERenewStatus = ERenewStatus
  ERenewStatusDesc = ERenewStatusDesc
  dialogVisible = false;
  deviceName = '';

  queryForm = {
    deviceName: "",
    storeName: ""
  }

  formData = {
    deviceName: "",
  };
  oldTableData = [];
  tableData = [];
  rules = {
    deviceName: [
      { required: true, message: '请输入设备名称', trigger: 'blur' }
    ]
  };

  mounted() {
    this.getData();
  }

  refreshDeviceTime() {
    const deviceList = this.tableData?.map((el) => el.deviceName)
    if (!deviceList?.length) return;
    this.showLoading();
    queryService({ deviceList }).then((res) => {
      const list = res.data || [];
      this.tableData.forEach(dev => {
        // 0表示未开通服务，1表示已开通未使用，2表示已存在过期时间
        const device = list?.find((el) => el.deviceName == dev.deviceName);
        let expired = "";
        if (device?.deviceStatus == 0) {
          expired = "未开通服务"
        }
        if (device?.deviceStatus == 1) {
          expired = "已开通未使用"
        }
        if (device?.deviceStatus == 2) {
          device.expirationTime ? (expired = this.transformDate(device.expirationTime)) : ''
        }
        dev.expired = expired;
        Object.assign(dev, device)
      });
      this.tableData = cloneDeep(this.tableData);
    }).finally(() => {
      this.hideLoading();
    })
  }


  getData() {
    this.showLoading();
    const params = {
      ...this.queryForm,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    }
    deviceList(params).then(res => {
      // this.tableData = res.data.list;
      this.itemTotal = res.data.total;
      this.tableData = res.data.list.map(dev => {
        // 0表示未开通服务，1表示已开通未使用，2表示已存在过期时间
        // const device = list?.find((el) => el.deviceName == dev.deviceName);
        let expired = "";
        if (dev?.deviceStatus == 0) {
          expired = "未开通服务"
        }
        if (dev?.deviceStatus == 1) {
          expired = "已开通未使用"
        }
        if (dev?.deviceStatus == 2) {
          dev.expirationTime ? (expired = this.transformDate(dev.expirationTime)) : ''
        }
        dev.expired = expired;
        return dev;
      });
    }).catch(err => {
    }).finally(() => {
      this.hideLoading();
    })
  }

  search() {
    this.resetCurrent();
    this.getData();
  }

  filterDataList() {
    const { storeName, deviceName } = this.queryForm;
    let list = this.oldTableData;
    if (storeName) {
      debugger
      list = this.oldTableData?.filter((device) => device.storeName?.toUpperCase().indexOf(storeName?.toUpperCase()) > -1)
    } 
    if (deviceName) {
      list = this.oldTableData?.filter((device) => device.deviceName?.toUpperCase().indexOf(deviceName?.toUpperCase()) > -1)
    }
    this.tableData = cloneDeep(list);
  }


  //替换表格时间格式
  formatDate(row, column) {
    // 获取单元格数据
    let date = row[column.property];
    if (date) {
      return this.transformDate(date)
    } else {
      return ''
    }
  }
  //标准时间格式转yyyy-MM-dd HH:mm:ss
  transformDate(date) {
    if (date) {
      let dt = new Date(date);
      return dt.getFullYear() + '-' +
          ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
          (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
          (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
          (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
          (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
    } else {
      return ''
    }
  }

  dialogClose() {
    this.formData = deepCopy(initFormData);
    this.$refs['validateForm'].resetFields();
    this.dialogVisible = false;
  }
  handleEdit(row) {
    let { id, deviceName } = row;
    this.formData = {
      deviceName
    };
    this.dialogVisible = true;
  }

  dialogConfirm() {
    this.$refs['validateForm'].validate((valid) => {
      if (valid) {
        activateDevice(this.formData).then(res => {
            this.getData();
           this.dialogClose();
          }).catch(err => {
            console.log(err);
            this.getData();
        });

      } else {
        return false;
      }
    })
  }

  settle(row) {
    this.$confirm('结算完成即表示已续费（财务已结算费用），确认已结算？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      settlement({deviceName: row.deviceName}).then(() => {
        this.refreshDeviceTime();
        this.$message({
          type: 'success',
          message: '操作成功'
        });
      })
    }).catch(() => {    
    });
  }

  renew(row) {
    this.$confirm('是否确认续费？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      renew({deviceName: row.deviceName,forceRenew: false}).then(() => {
        this.refreshDeviceTime();
        this.$message({
          type: 'success',
          message: '操作成功'
        });
      })
    })
  }


}
</script>



<style lang="scss" scoped src="./style.scss">
</style>
<style lang="scss" scoped>
.renew-status-2{
  color: red;
}
.el-icon-warning-outline{
  color: #666666;
  &:empty::after{
    content: "";
  }
}
</style>